import { Links, Meta, Outlet, Scripts, ScrollRestoration } from "@remix-run/react";
import GlobalModal, { type GlobalModalContextProps } from "./components/global-modal";
import { useState } from "react";
import "@/styles/global.scss";

export type GlobalModalContext = {
  modalProps: GlobalModalContextProps,
  useModal: React.Dispatch<React.SetStateAction<GlobalModalContextProps>>
}

export function Layout() {
  const [modalProps, useModal] = useState<GlobalModalContextProps>({
    type: "alert",
    open: false,
    title: "",
    body: "",
  })

  return (
    <html lang="ja">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        {/* <meta http-equiv="Content-Security-Policy" content={`\
          default-src 'self' https://*.google.com https://*.gstatic.com ;\
          style-src 'self' data: 'unsafe-inline' ;\
          font-src 'self' data: https://*.googleapis.com https://*.gstatic.com ;\
          img-src 'self' data: https://*.googletagmanager.com https://*.gstatic.com ;\
          script-src 'self' ;\
          frame-src 'self' https://*.google.com ;\
          connect-src 'self' https://*.googleapis.com https://*.google-analytics.com ;\
          worker-src 'self' blob: ;\
        `} /> */}
        <Meta />
        <Links />
      </head>
      <body className="bg-teal-100 min-h-screen">
        <Outlet context={{modalProps, useModal}} />
        <GlobalModal type={modalProps.type} open={modalProps.open} title={modalProps.title} body={modalProps.body} confirmedCallback={modalProps.confirmedCallback} useModal={useModal} />
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

export default function App() {
  return <Outlet />;
}
