export type GlobalModalType = "alert"|"confirm"|"custom"
export type GlobalModalContextProps = {
  type: GlobalModalType,
  open: boolean,
  title?: string,
  body?: string|React.ReactNode,
  confirmedCallback?: () => void,
  useModal?: React.Dispatch<React.SetStateAction<GlobalModalContextProps>>
}

export default function GlobalModal({
  type = "alert",
  open = true,
  title,
  body,
  confirmedCallback,
  useModal,
  children
}: GlobalModalContextProps & { children?: React.ReactNode }
) {

  return (
    <dialog id="global-modal" className="modal bg-teal-50 bg-opacity-75" open={open} onClose={(e) => {
      if(confirmedCallback && e.currentTarget.returnValue === "confirmed") {
        confirmedCallback()
      }
      if(useModal) {
        useModal({
          type,
          open: false,
          title,
          body,
          useModal,
        })
      }
    }}>
      <div className="modal-box">
        {
          !! title
          ? <h2 className="font-bold text-xl">{title}</h2>
          : null
        }
        {
          !! body
          ? <p className="py-4">{body}</p>
          : null
        }
        {children}
        <div className="modal-action">
          <form method="dialog">
            {
              type === "alert"
              ? <button className="btn btn-accent">OK</button>
              : type === "confirm"
              ? <div className="flex gap-3">
                  <button value="canceled" className="btn btn-outline">キャンセル</button>
                  <button value="confirmed" className="btn btn-accent">OK</button>
                </div>
              : null
            }
          </form>
        </div>
      </div>
    </dialog>
  )
}